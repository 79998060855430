export enum FeatureFlags {
  ShowLoanActivityTabWeb = 'showLoanActivityTabWeb',
  ShowMultiplePartnersWeb = 'showMultiplePartnersWeb',
  ShowAutoinvestWeb = 'showAutoinvestWeb',
  ShowRepaymentScheduleWeb = 'showRepaymentSchedule',
}

export const FallbackFeatureFlagValues = {
  [FeatureFlags.ShowLoanActivityTabWeb]: false,
  [FeatureFlags.ShowMultiplePartnersWeb]: false,
  [FeatureFlags.ShowAutoinvestWeb]: false,
  [FeatureFlags.ShowRepaymentScheduleWeb]: false,
};
